
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ProjectUser } from '@/types/project'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})

export default class Add extends Vue {
  @Prop() readonly dialogVisible2!: boolean
  @Prop() readonly areaList!: any

  private typeList = [] as any
  private projectUserList = [] as any

  private info = {
    orderTitle: '', // 主题
    orderContent: '', // 详情
    orderType: '', // 类型
    projectAreaId: '', // 作业区域
    address: '', // 地点
    orderUserId: '', // 指派处理人
    fileList: [] as any // 文件
  }

  private rules = {
    orderTitle: [
      { required: true, message: '请输入工单主题', trigger: ['blur', 'change'] }
    ],
    orderContent: [
      { required: true, message: '请输入工单详情', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: 'change' }
    ],
    address: [
      { required: true, message: '请输入作业地点', trigger: ['blur', 'change'] }
    ],
    orderUserId: [
      { required: true, message: '请选择处理人', trigger: ['blur', 'change'] }
    ],
    fileList: [
      { required: true, message: '请选择现场图片', trigger: ['blur', 'change'] }
    ]
  }

  get projectId () {
    return this.$route.query.projectId as any
  }

  created () {
    this.getWorkTypeList() // 工单类型
    this.getProjectUserList() // 获取项目下人员
  }

  // 加载工单类型
  getWorkTypeList () {
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      projectId: this.projectId,
      orderTypeStatus: '1'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: this.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.projectUserList = res || []
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        const info = JSON.parse(JSON.stringify(this.info))
        // console.log('info', this.info)
        this.$emit('onAddTask', info)
        this.handleClose()
      }
    })
  }

  handleClose () {
    this.$emit('update:dialogVisible2', false);
    (this.$refs.info as ElForm).resetFields()
  }
}
