
import { Component, Vue, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElTable } from 'element-ui/types/table'

import Addtask from './Addtask.vue'
@Component({
  components: { Addtask },
  name: 'AddMaintenanceDay',
  filters: {
    orderStatus: function (value: any) {
      if (value) {
        if (value === '0') {
          return '待反馈'
        } else if (value === '1') {
          return '待审核'
        } else if (value === '2') {
          return '已完成'
        } else if (value === '3') {
          return '已关闭'
        } else {
          return '--'
        }
      }
    },
    getStatusName: function (value: any) {
      // 签到状态 1正常 2缺上班签到 3缺下班签到 4上下班人数不一致
      if (value) {
        if (value === '1') {
          return '正常'
        } else {
          return '异常'
        }
      }
    },
    getStatusName2: function (value: any) {
      if (value) {
        if (value === '1') {
          return '正常'
        } else if (value === '2') {
          return '缺上班签到'
        } else if (value === '3') {
          return '缺下班签到'
        } else if (value === '4') {
          return '上下班人数不一致'
        } else {
          return '--'
        }
      }
    }
  }
})

export default class ScheduleAdd extends Vue {
  @Ref('table') readonly table!: ElTable
  private loading = false
  private loadingSubmit = false
  private projectUserList = [] as any
  private submitShow = false
  private activeName = '1'
  private info= {
    projectId: '', // 项目ID
    dailyDate: '', // 日报日期
    manageUserIds: [], // 项目管理人员IDS
    orderIds: [], // 养护工单IDS
    dailyBak: '', // 今日总结
    tomorrowOrderList: [] as any // 明日工单列表
  }

  // 控制是否显示
  private templateList = {
    attendance: false, // 考勤劳务
    orderIds: false, // 养护作业
    material: false, // 物料管理
    taiBan: false, // 台班劳务
    dailyBak: false, // 今日总结
    tomorrowOrder: false // 明日工单列表
  }

  private page = 1
  private total = 0
  private size = 10

  private dialogVisible = false // 工单类型
  private dialogVisible2 = false // 任务工单
  private workOrderForm = {
    orderTitle: '',
    orderType: ''
  }

  private areaList = [] as any // 作业区域

  private attendanceList = [] // 劳务考勤列表
  private typeList = [] // 工单类型
  private workOrderItems = [] // 工单列表
  private selectItems = [] // 选中的临时工单
  private materialList = [] // 物料列表
  private taiBanList = [] // 台班列表

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    dailyDate: [
      { required: true, message: '请选择日报日期', trigger: ['blur', 'change'] }
    ],
    manageUserIds: [
      { required: true, message: '请选择现场管理人员', trigger: ['blur', 'change'] }
    ]
  }

  get projectId () {
    return this.$route.query.projectId as any
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get dailyDate () {
    return this.$route.query.dailyDate as any
  }

  get dailyId () {
    return this.$route.query.dailyId as any
  }

  addTask () {
    this.dialogVisible2 = true
  }

  created () {
    // console.log('created3', this.$route.query.projectId)
    this.getProjectUserList() // 获取项目下人员
    this.getAreaList() // 获取作业区域
    this.info.projectId = this.projectId
    this.info.dailyDate = this.dailyDate
    if (this.dailyId) {
      this.getMaintenanceDayDetails() // 日报详情
    }
    this.getProjectDailyTemplate() // 查询项目的日报项配置列表
  }

  getMaintenanceDayDetails () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyShareDetail, {
      dailyId: this.dailyId
    }).then(res => {
      // res.dailyOptionIds.forEach((item: any) => {
      //   if (item === '1') {
      //     this.getAttendance() // 查询项目日报-劳务考勤
      //     this.templateList.attendance = true
      //   } else if (item === '2') {
      //     this.templateList.orderIds = true
      //     this.getDailyOrder() // 查询项目日报-养护作业
      //   } else if (item === '3') {
      //     this.templateList.material = true
      //     this.getMaterial({
      //       projectId: this.projectId,
      //       detailedType: '1',
      //       storageDate: this.dailyDate
      //     })
      //   } else if (item === '4') {
      //     this.templateList.taiBan = true
      //     this.getTaiBan()
      //   } else if (item === '5') {
      //     this.templateList.dailyBak = true
      //   } else if (item === '6') {
      //     this.templateList.tomorrowOrder = true
      //     this.getTomorrowPlan() // 查询项目日报-明日计划
      //   }
      // })

      this.info = res
      this.info.manageUserIds = res.manageUserList.map((item: any) => {
        return item.manageUserId
      }) || []
    })
  }

  getProjectDailyTemplate () {
    this.$axios.get(this.$apis.maintenanceDaily.selectProjectOptionList, {
      projectId: this.projectId
    }).then(res => {
      // console.log('项目的日报项配置列表', res)
      res.forEach((item: any) => {
        if (item.dailyOptionType === '1') {
          this.getAttendance() // 查询项目日报-劳务考勤
          this.templateList.attendance = true
        } else if (item.dailyOptionType === '2') {
          // 校验会有问题
          this.templateList.orderIds = true
          if (this.dailyId) {
            this.getDailyOrder() // 查询项目日报-养护作业
          } else {
            this.getorders() // 获取养护作业（上一天的明日计划）
          }
        } else if (item.dailyOptionType === '3') {
          this.templateList.material = true
          this.getMaterial({
            projectId: this.projectId,
            detailedType: '1',
            storageDate: this.dailyDate
            // checkoutDate: this.dailyDate
          })
        } else if (item.dailyOptionType === '4') {
          this.templateList.taiBan = true
          this.getTaiBan()
        } else if (item.dailyOptionType === '5') {
          this.templateList.dailyBak = true
        } else if (item.dailyOptionType === '6') {
          this.templateList.tomorrowOrder = true
          if (this.dailyId) {
            this.getTomorrowPlan() // 查询项目日报-明日计划
          }
        }
      })
      // this.attendanceList = res.attendanceList
    })
  }

  getTomorrowPlan () {
    this.$axios.get(this.$apis.maintenanceDaily.selectDailyTomorrowPlan, {
      dailyId: this.dailyId
    }).then(res => {
      // console.log('res', res)
      this.info.tomorrowOrderList = res.orderList
    })
  }

  handleClick (value: any) {
    // console.log('handle', value)
    if (value === '1') {
      this.getMaterial({
        projectId: this.projectId,
        detailedType: '1',
        storageDate: this.dailyDate
        // checkoutDate: this.dailyDate
      })
    } else {
      this.getMaterial({
        projectId: this.projectId,
        detailedType: '2',
        // storageDate: this.dailyDate
        checkoutDate: this.dailyDate
      })
    }
  }

  // 获取区域
  getAreaList () {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByList, {
      projectId: this.projectId
    }).then(res => {
      this.areaList = res || []
    })
  }

  // 项目人员列表
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: this.projectId
    }).then(res => {
      // console.log('用户列表', res)
      this.projectUserList = res || []
    })
  }

  // 获取考勤
  getAttendance () {
    this.$axios.get(this.$apis.maintenanceDaily.selectLabourServiceStatisticByDaily, {
      projectId: this.projectId,
      attendanceDate: this.dailyDate
    }).then(res => {
      // console.log('劳务考勤', res)
      this.attendanceList = res.attendanceList
    })
  }

  //  获取养护作业（上一天的明日计划，新增时使用）
  getorders () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhProjectDailyTomorrowPlanList, {
      projectId: this.projectId,
      dailyDate: this.dailyDate
    }).then(res => {
      console.log('养护res', res)
      this.info.orderIds = res || []
      this.selectItems = res || []
    })
  }

  //  获取养护作业（编辑时使用）
  getDailyOrder () {
    this.$axios.get(this.$apis.maintenanceDaily.selectDailyOrder, {
      dailyId: this.dailyId
    }).then(res => {
      this.info.orderIds = res.orderlist || []
      this.selectItems = res.orderlist || []
    })
  }

  // 获取物料
  getMaterial (params: any) {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhDetailedDaily, params).then(res => {
      this.materialList = res.yhDetailedList
    })
  }

  // 获取台班
  getTaiBan () {
    this.$axios.get(this.$apis.maintenanceDaily.selectYhMachineTeamLineByList, {
      projectId: this.projectId,
      machineTeamDate: this.dailyDate
    }).then(res => {
      // console.log('劳务考勤', res)
      this.taiBanList = res.list
    })
  }

  // 展示工单
  showWorkOrder () {
    // this.page = 1
    this.dialogVisible = true
    this.getWorkTypeList()
    this.getWorkList()
    this.$nextTick(() => {
      // console.log('this.info.orderIds', this.info.orderIds)
      // console.log('打开弹框this.selectItems', this.selectItems)
      this.table.clearSelection()
      this.info.orderIds.forEach(item => {
        this.table.toggleRowSelection(item, true)
      })
    })
  }

  // 加载工单类型
  getWorkTypeList () {
    // this.searchInfo.orderType = ''
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      projectId: this.info.projectId,
      orderTypeStatus: '1'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 加载工单
  getWorkList () {
    // this.tableData.loading = true
    this.$axios.get(this.$apis.workOrder.selectWorkOrderByPage, {
      ...this.workOrderForm,
      projectId: this.info.projectId,
      page: this.page,
      size: this.size,
      orderOtherStatus: '1'
    }).then((res) => {
      this.total = res.total || 0
      this.workOrderItems = res.list || []
    }).finally(() => {
      // this.tableData.loading = false
    })
  }

  onSearchWorkOrder () {
    this.page = 1
    this.getWorkList()
  }

  // selectable (row: any) {
  //   return !this.info.orderIds.some((item: any) => item.orderId === row.orderId)
  // }

  selectionLineChangeHandle (val: any) {
    this.selectItems = val
  }

  // 选择工单
  confirmWorkOrder () {
    // console.log('选择养护工单', this.formData.flowOrderList)
    // console.log('this.selectItems', this.selectItems)
    this.info.orderIds = this.selectItems
    // console.log('items', items)
    this.dialogVisible = false
  }

  // 删除养护作业
  onDelete (index: any) {
    this.info.orderIds.splice(index, 1)
    // this.selectItems.splice(index, 1)
  }

  // 删除明日计划
  onDelete2 (index: any) {
    this.info.tomorrowOrderList.splice(index, 1)
    // this.selectItems.splice(index, 1)
  }

  // 添加明日任务
  onAddTask (item: any) {
    this.info.tomorrowOrderList.push(item);
    (this.$refs.task as any).handleClose()
  }

  onSubmit () {
    // console.log('info', this.info);
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.templateList.orderIds && !this.info.orderIds.length) {
          this.$message({ type: 'warning', message: '请至少添加一条养护作业' })
          return
        }
        if (this.templateList.dailyBak && !this.info.dailyBak) {
          this.$message({ type: 'warning', message: '请输入今日总结' })
          return
        }
        if (!this.info.orderIds.length && !this.info.dailyBak && !this.info.tomorrowOrderList.length) {
          this.$message({ type: 'warning', message: '请至少填写一项日报项' })
          return
        }

        const params = JSON.parse(JSON.stringify(this.info))
        params.orderIds = params.orderIds.map((item: any) => {
          return item.orderId
        })
        this.submitShow = true
        const url = this.dailyId ? this.$apis.maintenanceDaily.updateYhProjectDaily : this.$apis.maintenanceDaily.insertYhProjectDaily
        this.$axios.post(url, params).then(() => {
          this.$message.success('操作成功')
          // this.handleClose()
          this.$router.back()
          // this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  getOrderName (value: any) {
    if (value) {
      const target = this.projectUserList.find((item: any) => {
        return item.userId === value
      })
      return target.userName
    }
  }

  getAreaName (value: any) {
    if (value) {
      const target = this.areaList.find((item: any) => {
        return item.projectAreaId === value
      })
      return target.areaName
    }
  }
}
